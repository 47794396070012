<template>
	<li class="nav-item">
		<a class="nav-link" data-toggle="tab" :class="{ active: active }" v-on:click="setIndex">
			<span class="nav-icon">
				<i :class="icon"></i>
			</span>
			<span class="nav-text">{{ text }}</span>
		</a>
	</li>
</template>

<script>
export default {
	name: 'PrinorTabs-Nav',
	props: {
		icon: String,
		text: String,
	},
	data() {
		return {
			active: false,
			index: -1,
		};
	},
	methods: {
		setIndex: function() {
			this.$parent.$emit('setIndex', this.index);
		},
	},
};
</script>

<style scoped>
li.nav-item:hover {
	cursor: pointer;
}
</style>
