<template>
	<div class="card card-custom">
		<div class="card-header card-header-tabs-line">
			<div class="card-title">
				<h3 class="card-label">{{ title }}</h3>
			</div>
			<div class="card-toolbar">
				<ul class="nav nav-tabs nav-bold nav-tabs-line my-0">
					<slot name="nav"></slot>
				</ul>
			</div>
		</div>
		<div class="card-body">
			<div class="tab-content">
				<slot></slot>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'PrinorTabs',
	props: {
		title: String,
	},
	data() {
		return {
			navItems: [],
			contentItems: [],
			activeIndex: 0,
		};
	},
	mounted() {
		let that = this;
		this.$on('setIndex', this.setIndex);

		let navIndex = 0;
		let contentIndex = 0;
		if (this.$children.length > 0) {
			this.$children.forEach(child => {
				if (child.$options.name === 'PrinorTabs-Nav') {
					child.index = navIndex;
					that.navItems.push(child);
					navIndex++;
				} else if (child.$options.name === 'PrinorTabs-Content') {
					child.index = contentIndex;
					that.contentItems.push(child);
					contentIndex++;
				}
			});

			this.activateIndex();
		}
	},
	methods: {
		setIndex: function(index) {
			if (index >= 0 && index < this.contentItems.length) {
				this.activeIndex = index;
				this.activateIndex();
			}
		},
		activateIndex: function() {
			this.navItems.forEach(item => {
				item.active = false;
			});
			this.contentItems.forEach(item => {
				item.active = false;
			});

			this.navItems[this.activeIndex].active = true;
			this.contentItems[this.activeIndex].active = true;
		},
	},
};
</script>

<style scoped></style>
