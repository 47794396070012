<template>
	<div class="tab-pane active show" role="tabpanel" v-if="active">
		<slot></slot>
	</div>
</template>

<script>
export default {
	name: 'PrinorTabs-Content',
	data() {
		return {
			active: false,
		};
	},
};
</script>

<style scoped></style>
