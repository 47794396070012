<template>
	<div>
		<ContentLoading v-if="isLoading" size="1.5"></ContentLoading>
		<div class="row" v-if="!isLoading">
			<modal name="csv-config">
				<div class="card card-custom">
					<div class="card-header card-header-tabs-line">
						<div class="card-title">
							<h3 class="card-label">{{ $t('VIEWS.Dashboard.Mail.CSV.title') }}</h3>
						</div>
					</div>
					<div class="card-body">
						<div class="row">
							<div class="col-12">
								<div class="form-group">
									<label for="columnSelect">{{ $t('VIEWS.Dashboard.Mail.CSV.column') }}</label>
									<select class="form-control" id="columnSelect" v-model="columnSelect">
										<option :value="index" v-for="(column, index) in columnSelectData" :key="'s' + column + index">{{ column }}</option>
									</select>
								</div>
							</div>
						</div>
					</div>
					<div class="card-footer d-flex justify-content-between">
						<button type="button" v-on:click.prevent="hideModal" class="btn btn-outline-danger">{{ $t('GENERAL.Buttons.cancel') }}</button>
						<button type="button" v-on:click.prevent="addAddressesCsv" class="btn btn-success">{{ $t('GENERAL.Forms.save') }}</button>
					</div>
				</div>
			</modal>
			<div class="col-12">
				<PrinorTabs :title="$t('VIEWS.Dashboard.Mail.title')">
					<template v-slot:nav>
						<PrinorTabsNav icon="fas fa-pencil-alt" :text="$t('VIEWS.Dashboard.Mail.Nav.text')"></PrinorTabsNav>
						<PrinorTabsNav icon="fas fa-address-book" :text="$t('VIEWS.Dashboard.Mail.Nav.address')"></PrinorTabsNav>
						<PrinorTabsNav icon="fas fa-paper-plane" :text="$t('VIEWS.Dashboard.Mail.Nav.send')"></PrinorTabsNav>
						<PrinorTabsNav icon="fas fa-history" :text="$t('VIEWS.Dashboard.Mail.Nav.history')"></PrinorTabsNav>
					</template>
					<template v-slot:default>
						<PrinorTabsContent>
							<div class="form-group">
								<label>{{ $t('VIEWS.Dashboard.Mail.Text.subject') }} <span class="text-danger">*</span></label>
								<div class="input-group">
									<input type="text" class="form-control" v-model="mail.subject" />
								</div>
								<span class="form-text text-muted">{{ $t('VIEWS.Dashboard.Mail.Text.subjectInfo') }}</span>
							</div>

							<div class="form-group mb-1">
								<label for="exampleTextarea">{{ $t('VIEWS.Dashboard.Mail.Text.text') }} <span class="text-danger">*</span></label>
								<textarea class="form-control" id="exampleTextarea" rows="4" v-model="mail.text"></textarea>
								<span class="form-text text-muted">{{ $t('VIEWS.Dashboard.Mail.Text.textInfo') }}</span>
							</div>
						</PrinorTabsContent>
						<PrinorTabsContent>
							<div class="row">
								<div class="col-12">
									<div class="alert alert-success mb-6 p-5 d-block" role="alert">
										<h4 class="alert-heading">{{ $t('VIEWS.Dashboard.Mail.Address.filetypes') }}</h4>
										<p>{{ $t('VIEWS.Dashboard.Mail.Address.filetypesInfoOne') }}</p>
										<div class="border-bottom border-white opacity-20 mb-5"></div>
										<p class="mb-0">{{ $t('VIEWS.Dashboard.Mail.Address.filetypesInfoTwo') }}</p>
									</div>
								</div>
							</div>
							<div class="row">
								<div class="col-12 col-md-6">
									<div class="form-group validated">
										<label>{{ $t('VIEWS.Dashboard.Mail.Address.addAddress') }}</label>
										<div class="input-group">
											<input type="text" class="form-control" :class="addressError ? 'is-invalid' : ''" v-model="addressInput" />
											<div class="input-group-append">
												<button class="btn btn-success" type="button" v-on:click.prevent="addAddress">+</button>
											</div>
										</div>
										<div class="invalid-feedback" v-if="addressError">{{ $t('VIEWS.Dashboard.Mail.Address.addAddressError') }}</div>
										<span class="form-text text-muted" v-if="!addressError">{{ $t('VIEWS.Dashboard.Mail.Address.addAddressInfo') }}</span>
									</div>
								</div>
								<div class="col-12 col-md-6">
									<div class="form-group">
										<label>{{ $t('VIEWS.Dashboard.Mail.Address.uploadFile') }}</label>
										<div></div>
										<div class="custom-file">
											<input type="file" class="custom-file-input" accept=".txt,.csv" ref="uploadedFile" v-on:change="fileChange" />
											<label class="custom-file-label">{{ $t('VIEWS.Dashboard.Mail.Address.uploadSelect') }}</label>
											<span class="form-text text-muted">{{ $t('VIEWS.Dashboard.Mail.Address.uploadInfo') }}</span>
										</div>
									</div>
								</div>
								<div class="col-12">
									<hr />
								</div>
								<div class="col-12 col-md-6">
									<h6>{{ $t('VIEWS.Dashboard.Mail.Address.addressList') }}</h6>
									<div class="prinor-table">
										<b-table ref="mailHistory" :data="mailList" :paginated="true" :per-page="10">
											<b-table-column field="mail" :label="$t('GENERAL.General.mail')" v-slot="props" :sortable="true" :searchable="true">
												<template>
													<span class="truncate">{{ props.row.mail }}</span>
												</template>
											</b-table-column>
											<b-table-column :label="$t('GENERAL.Forms.delete')" v-slot="props" :sortable="false" :searchable="false">
												<template>
													<button class="btn btn-outline-danger" @click.prevent="deleteMail(props.row)">{{ $t('GENERAL.Forms.delete') }}</button>
												</template>
											</b-table-column>
										</b-table>
									</div>
								</div>
							</div>
						</PrinorTabsContent>
						<PrinorTabsContent>
							<div class="row">
<!--								<div class="col-12 col-md-6">-->
<!--									<div class="form-group">-->
<!--										<label>{{ $t('VIEWS.Dashboard.Mail.Send.from') }}</label>-->
<!--										<div class="radio-inline">-->
<!--											<label class="radio">-->
<!--												<input type="radio" name="mailSender" v-model="mail.prinorServer" v-bind:value="true" />-->
<!--												<span></span>PrinorQuest</label-->
<!--											>-->
<!--											<label class="radio">-->
<!--												<input type="radio" name="mailSender" v-model="mail.prinorServer" v-bind:value="false" />-->
<!--												<span></span>{{ $t('VIEWS.Dashboard.Mail.Send.companyServer') }}</label-->
<!--											>-->
<!--										</div>-->
<!--										<span class="form-text text-muted">{{ $t('VIEWS.Dashboard.Mail.Send.companyServerInfo') }}</span>-->
<!--									</div>-->
<!--								</div>-->
<!--								<div class="col-12 col-md-6">-->
<!--									<div class="form-group">-->
<!--										<label>{{ $t('VIEWS.Dashboard.Mail.Send.sendDate') }}</label>-->
<!--										<toggle-button v-model="dateSection" class="input-group" />-->
<!--									</div>-->
<!--									<div v-if="dateSection === true">-->
<!--										<div class="row">-->
<!--											<div class="col-8">-->
<!--												<div class="form-group">-->
<!--													<label>{{ $t('GENERAL.General.time.date') }}</label>-->
<!--													<div class="input-group">-->
<!--														<vc-date-picker v-model="mail.date" :popover="{ visibility: 'click' }" :locale="$t('GENERAL.General.locale')">-->
<!--															<template v-slot="{ inputValue, inputEvents }">-->
<!--																<input class="form-control" :value="inputValue" v-on="inputEvents" readonly="true" style="width: 100%;"/>-->
<!--															</template>-->
<!--														</vc-date-picker>-->
<!--													</div>-->
<!--												</div>-->
<!--											</div>-->
<!--										</div>-->
<!--										<div class="row">-->
<!--											<div class="col-4">-->
<!--												<div class="form-group">-->
<!--													<label>{{ $t('GENERAL.General.time.time') }}</label>-->
<!--													<div class="input-group">-->
<!--														<vue-timepicker v-model="mail.time" style="width: 100%;" input-class="form-control" input-width="100%"></vue-timepicker>-->
<!--													</div>-->
<!--												</div>-->
<!--											</div>-->
<!--										</div>-->
<!--									</div>-->
<!--								</div>-->
								<div class="col-12">
									<button type="button" class="btn btn-success" v-on:click.prevent="sendMails">{{ $t('VIEWS.Dashboard.Mail.Send.sendMail') }}</button>
								</div>
							</div>
						</PrinorTabsContent>
						<PrinorTabsContent>
							<div class="row">
								<div class="col-12 prinor-table">
									<b-table ref="mailHistory" :data="history" :paginated="true" :per-page="10">
										<b-table-column field="email" :label="$t('GENERAL.General.mail')" v-slot="props" :sortable="true" :searchable="true">
											<template>
												<span class="truncate">{{ props.row.email }}</span>
											</template>
										</b-table-column>
										<b-table-column field="created_at" :label="$t('VIEWS.Dashboard.Mail.History.sendAt')" v-slot="props" :sortable="true" :searchable="true">
											<template>
												<span class="truncate">{{ props.row.created_at }}</span>
											</template>
										</b-table-column>
									</b-table>
								</div>
							</div>
						</PrinorTabsContent>
					</template>
				</PrinorTabs>
			</div>
		</div>
		<!--end::Dashboard-->
	</div>
</template>

<script>
import ContentLoading from '@/view/component/misc/ContentLoading';
import ApiService from '@/core/services/api.service';
import { PRINOR_TOASTS } from '@/core/services/toast.service';
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module';
import PrinorTabs from '@/view/component/misc/TabbedIcons/PrinorTabs';
import PrinorTabsNav from '@/view/component/misc/TabbedIcons/PrinorTabs-Nav';
import PrinorTabsContent from '@/view/component/misc/TabbedIcons/PrinorTabs-Content';

export default {
	name: 'EMail',
	components: { PrinorTabsContent, PrinorTabsNav, PrinorTabs, ContentLoading },
	data: function() {
		return {
			isLoading: true,
			survey: null,
			history: null,
			dateSection: false,
			selectedDate: null,
			selectedTime: null,
			csvData: null,
			columnSelect: -1,
			columnSelectData: [],
			addressError: false,
			addressInput: '',
			mailList: [],
			mail: {
				prinorServer: false,
				subject: '',
				text: '',
				addresses: [],
				date: '',
				time: '',
			},
		};
	},
	mounted() {
		this.$store.dispatch(SET_BREADCRUMB, [{ title: this.$t('MENU.Survey.mail') }]);

		this.get().then(data => {
			if (data.success === true) {
				this.isLoading = false;
				this.survey = data.data.survey;
				this.history = data.data.history;
			} else {
				PRINOR_TOASTS.general.error(this, this.$t('GENERAL.Toasts.generalError'), data.error.code);
			}
		});
	},
	methods: {
		get() {
			return new Promise(resolve => {
				ApiService.setHeader();
				ApiService.get('share/email/' + this.$route.params.id)
					.then(({ data }) => {
						resolve(data);
					});
					// .catch(({ response }) => {
					// 	console.error('Dashboard', response);
					// });
			});
		},
		addAddress() {
			if (this.validateEmail(this.addressInput)) {
				this.mailList.push({ mail: this.addressInput });
				this.addressInput = '';
				this.addressError = false;
			} else {
				this.addressError = true;
			}
		},
		fileChange: function(event) {
			let that = this;
			const file = event.target.files[0];

			if (file.type === 'text/csv') {
				let reader = new FileReader();
				reader.readAsText(file, 'UTF-8');
				reader.onload = evt => {
					let test = window.Papa.parse(evt.target.result);

					if (test.data.length > 0) {
						that.csvData = test.data;
						this.columnSelectData = [];
						test.data[0].forEach(name => {
							this.columnSelectData.push(name);
						});
					}

					this.$modal.show('csv-config');
				};
			} else if (file.type === 'text/plain') {
				let reader = new FileReader();

				reader.onload = function() {
					let lines = this.result.split('\n');
					for (let line = 0; line < lines.length; line++) {
						if (that.validateEmail(lines[line])) {
							that.mailList.push({ mail: lines[line] });
						}
					}
				};

				reader.readAsText(file);
			} else {
				this.$notify({
					group: 'error',
					title: 'Fehler',
					text: 'Es handelt sich nicht um eine gültige .txt oder .csv Datei!',
					type: 'error',
					max: 5,
					speed: 500,
					duration: -1,
				});
			}
		},
		addAddressesCsv: function() {
			let col = this.columnSelect;
			if (col > -1) {
				if (this.csvData.length > 0) {
					this.csvData.forEach(row => {
						if (this.validateEmail(row[col])) {
							this.mailList.push({ mail: row[col] });
						}
					});
					this.$modal.hide('csv-config');
				}
			}
		},
		validateEmail: function(mail) {
			return mail && mail.length > 0 && mail.indexOf('@') !== -1;
		},
		sendMails: function() {
			this.mail.addresses = [];
			this.mailList.forEach(row => {
				if (this.validateEmail(row.mail)) {
					this.mail.addresses.push(row.mail);
				}
			});

			ApiService.setHeader();
			ApiService.post('share/email/' + this.$route.params.id, this.mail)
				.then(({ data }) => {
					if (data.success === true) {
						PRINOR_TOASTS.general.confirmation(this, 'successfully sent');
					}
				});
				// .catch(({ response }) => {
				// 	console.error('Dashboard', response);
				// });
		},
		deleteMail: function(row) {
			this.mailList = this.mailList.filter(function(value) {
				return value.mail !== row.mail;
			});
		},
		hideModal: function() {
			this.$modal.hide('csv-config');
		},
	},
};
</script>

<style scoped></style>
